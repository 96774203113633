@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #f8f8f8;
}

html * {
  font-family: "Montserrat";
}

.no-ring:focus {
  outline: none;
  box-shadow: none;
}